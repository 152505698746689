
import Vue from 'vue';
import config from "@/config";

export default Vue.extend({
    name: 'verticalLead',
    components: {},
    data() {
        return {
            title:'历任领导',
            isZoomed:false,
            events: [],
            params:{ server:'1',category_id:'9',event_id:''},
            details:{},
          }
    },
    created() {
        this.postUserInfo();
    },
    methods: {
        openimg(event) {
            this.details = event
            this.isZoomed = !this.isZoomed
        },
        getFullImagePath(relativePath: string) {
            return `${config.domain}${relativePath}`;
        },
        goBack(){
            this.$router.replace({path:'/home'});
        },
        async postUserInfo(classify) {
            try {
                const result = await this.$api.postList(this.params);
                this.events = result.data.list
            } catch (error) {
                console.log('error', error)
            }
        }
    }
});
